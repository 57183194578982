/* RESET RULES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* @import url("https://fonts.googleapis.com/css?family=Lato:400,700&display=swap"); */

:root {
  --page-header-bgColor: #242e42;
  --page-header-bgColor-hover: #1d2636;
  --page-header-txtColor: #dde9f8;
  --page-header-headingColor: #7889a4;
  --page-header-width: 220px;
  --page-content-bgColor: #f0f1f6;
  --page-content-txtColor: #171616;
  --page-content-blockColor: #fff;
  --white: #fff;
  --black: #333;
  --blue: #00b9eb;
  --red: #ec1848;
  --border-radius: 4px;
  --box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a,
button {
  color: inherit;
}

a {
  text-decoration: none;
}

button {
  background: none;
  cursor: pointer;
}

/* input { */
  /* -webkit-appearance: none; */
/* } */

button,
input {
  border: none;
}

svg {
  display: block;
}

body {
  font: 16px/1.5 "Lato", sans-serif;
}


/* HEADER STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-header {
  /* position: fixed; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  /* padding-top: 20px; */
  /* width: var(--page-header-width); */
  width: 212px;
  color: var(--page-header-txtColor);
  background: var(--page-header-bgColor);
}

/*In case you prefer an absolutely positioned header that covers the full page height, add these styles*/
/*body {
  position: relative;
}

.page-header {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}*/

/*remove these styles*/
/*.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}*/

.page-header nav {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.page-header .logo {
  display: block;
  max-width: 120px;
  margin: 0 15px;
}

.page-header .toggle-mob-menu {
  display: none;
  margin-left: 5px;
  padding: 4px;
  background: var(--page-content-blockColor);
  border-radius: var(--border-radius);
}

.page-header .toggle-mob-menu svg {
  fill: var(--black);
  transition: transform 0.2s;
}

.page-header .admin-menu {
  display: flex;
  flex-direction: column;
}

/* .page-header .admin-menu li:nth-last-child(2) {
  margin-bottom: 35px;
} */

.page-header .admin-menu li:last-child {
  margin-top: auto;
  /* margin-bottom: 20px; */
}

.page-header .admin-menu li > * {
  width: 100%;
  padding: 12px 15px;
}

.page-header .admin-menu .menu-heading h3 {
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 12px;
  margin-top: 12px;
  color: var(--page-header-headingColor);
}

.page-header .admin-menu svg {
  width: 20px;
  height: 20px;
  fill: var(--page-header-txtColor);
  margin-right: 10px;
  transition: fill 0.2s;
}

.page-header .admin-menu a,
.page-header .admin-menu button {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  transition: background 0.2s, color 0.2s;
}

.page-header .admin-menu a:hover,
.page-header .admin-menu a:focus,
.page-header .admin-menu button:hover,
.page-header .admin-menu button:focus {
  background: var(--page-header-bgColor-hover);
  color: var(--blue);
  outline: none;
}

.page-header .admin-menu-active {
  background: var(--page-header-bgColor-hover);
  color: var(--blue);
  outline: none;
}



.page-header .admin-menu a:hover svg,
.page-header .admin-menu a:focus svg,
.page-header .admin-menu button:hover svg,
.page-header .admin-menu button:focus svg {
  fill: var(--blue);
}


/* PAGE CONTENT STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-content {
  position: relative;
  /* left: var(--page-header-width); */
  /* width: calc(100% - var(--page-header-width)); */
  width: 100%;
  min-height: 100vh;
  padding: 30px;
  color: var(--page-content-txtColor);
  background: var(--page-content-bgColor);
}

.search-and-user {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 50px;
  align-items: center;
  background: var(--page-content-bgColor);
  /* margin-bottom: 30px; */
  padding: 15px
}

.search-and-user form {
  position: relative;
}

.search-and-user input[type="search"] {
  width: 100%;
  height: 50px;
  font-size: 1.5rem;
  padding-left: 15px;
  background: var(--page-content-blockColor);
  color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.search-and-user ::placeholder {
  color: var(--page-content-txtColor);
}

.search-and-user form svg {
  width: 26px;
  height: 26px;
  fill: var(--page-content-txtColor);
}

.search-and-user form button {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.search-and-user .admin-profile {
  display: flex;
  align-items: center;
}

.search-and-user .admin-profile .greeting {
  margin: 0 10px 0 20px;
}

.search-and-user .admin-profile svg {
  width: 30px;
  height: 30px;
}

.search-and-user .admin-profile .notifications {
  position: relative;
}

.search-and-user .admin-profile .badge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -3px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 10px;
  color: var(--white);
  background: var(--red);
}

.grid {
  display: grid;
  grid-gap: 30px;
}

.page-content .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.page-content .grid > article {
  display: flex;
  /* height: 300px; */
  background: var(--page-content-blockColor);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.page-content article {
  display: flex;
  /* height: 300px; */
  background: var(--page-content-blockColor);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.page-content .grid > article:first-child,
.page-content .grid > article:last-child {
  grid-column: 1 / -1;
}


/* MQ RULES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (max-width: 767px) {
  .page-header,
  .page-content {
    position: static;
    width: 100%;
  }
   
  .page-header {
    padding: 10px;
  }

  .page-header nav {
    flex-direction: row;
  }

  .page-header .logo {
    margin: 0;
    height: 35px;
  }

  .page-header .toggle-mob-menu {
    display: block;
  }

  .page-header .admin-menu {
    position: absolute;
    left: 98px;
    top: 57px;
    margin-top: 0;
    z-index: 2;
    border-radius: var(--border-radius);
    background: var(--page-header-bgColor);
    visibility: hidden;
    opacity: 0;
    transform: scale(0.95);
    transition: all 0.2s;
  }

  .page-header .admin-menu li:nth-last-child(2) {
    margin-bottom: 12px;
  }
  
  .page-header .admin-menu li:last-child,
  .search-and-user .admin-profile .greeting {
    display: none;
  }
  
  .page-content {
    min-height: 0;
    padding: 10px;
  }

  .page-content .grid {
    grid-gap: 10px;
  }

  .search-and-user {
    position: absolute;
    left: 131px;
    top: 10px;
    padding: 0;
    grid-column-gap: 5px;
    width: calc(100% - 141px);
    border-radius: var(--border-radius);
    background: transparent;
  }

  .search-and-user input[type="search"] {
    font-size: 1rem;
    height: 35px;
  }

  .search-and-user form svg {
    width: 18px;
    height: 18px;
  }

  .search-and-user .admin-profile svg {
    fill: var(--white);
  }
}

@media screen and (max-width: 400px) {
  .page-content .grid > article {
    grid-column: 1 / -1;
  }
}


/* BODY CLASSES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.mob-menu-opened .toggle-mob-menu svg {
  transform: rotate(180deg);
}

.mob-menu-opened .page-header .admin-menu {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .collapsed .page-header {
    width: 40px;
  }

  .collapsed .page-header .admin-menu li > * {
    padding: 10px;
  }

  .collapsed .page-header .logo,
  .collapsed .page-header .admin-menu span,
  .collapsed .page-header .admin-menu .menu-heading {
    display: none;
  }

  .collapsed .page-header .admin-menu svg {
    margin-right: 0;
  }

  .collapsed .page-header .collapse-btn svg {
    transform: rotate(180deg);
  }

  .collapsed .page-content {
    left: 40px;
    width: calc(100% - 40px);
  }
}


/* FOOTER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  font-size: 0.85rem;
  text-align: right;
  margin-top: 10px;
}

.page-footer span {
  color: var(--red);
}

/* USER LIST
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.list-header > div {
  border: 1px solid gray;
  border-right: 0;
  border-bottom: 0;
  padding: 3px 10px;
  font-weight: bold;
}
.list-user{
  border: 1px solid gray;
  border-right: 0;
  border-bottom: 0;
  padding: 10px;
  font-size: 13px;
}
.pagination {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
}

.pagination ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}
.pagination li {
  display: inline-block;
  padding: 5px 10px;
  list-style: 'none';
}

.pages .pagination{
  display: inline-block;
  padding: 5px 10px;
}
.active a {
  color: blue;
}

a:focus {
  outline:none;
}

/* USER LIST
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Full-width input fields */
input[type=text], input[type=password] , input[type=email], input[type=number]{
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 16px;
}

/* Center the image and position the close button */
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
  position: relative;
}

img.avatar {
  width: 40%;
  border-radius: 50%;
}

.sellButton > span {
  display: flex;
  text-align: center;
  color: #ecf0f1;
  padding: 10px 20px;
  border-radius: 3px;
  background-color: #d35400;
}

input.sellButton{
  display: flex;
  text-align: center;
  color: #ecf0f1;
  padding: 20px;
  border-radius: 3px;
  background-color: #d35400;
}

.admin-search-container {
  float: right;
  border: 1px solid silver;
  border-radius: 3px;
  margin: 10px;
  /* width: 350px; */
  /* height: 50px; */
}

.admin-search-container input[type=text] {
  padding: 6px 10px;
  margin-top: 8px;
  font-size: 17px;
  border: none;
  background: transparent;
}
.admin-search-container input[type=text]:focus {
  outline: none;
}

 .admin-search-container button {
  float: right;
  padding: 6px 10px;
  background: #ddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.admin-search-container button:hover {
  background: #ccc;
}

.admin-suggest-popup-container {
  margin-left: 10px;
  margin-right: 10px;
  border-left: 1px solid silver;
  border-right: 1px solid silver;
  border-bottom:  1px solid silver;
  background-color: #fff;
  height: 40px
}

.admin-suggest-popup-container > button {
  border: none;
  margin: 10px;
  font-size: 16px;
}

.message-container {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-list-user-container {
  display: flex;
  flex-direction: row;
}
.admin-list-user-container:hover {
  background-color: silver;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.tr-data-container:hover {
  background-color: silver;
}

.tr-child-data-container:hover {
  background-color: rgb(231, 222, 222);
}

/* tr:nth-child(even) {
  background-color: #dddddd;
} */

.data-modal-container {
  position: fixed;
  z-index: 1;
  padding-top: 60px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.max-lines {
  width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-area {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 16px;
}

.list-container {
  margin: 10px;
  /* border: 1px solid silver; */
  width: 100%;
  min-height: 200px
}

.list-tab-button {
  padding: 10px;
  font-size: 20px;
  color: silver;
}

.list-tab-button:focus {
  outline: none;
}

.list-tab-button:hover {
  color: black;
}

.list-tab-button-active {
  padding: 10px;
  font-size: 20px;
  color: black;
  outline: none;
}

.list-sub-container {
  margin: 10px;
}

.item-list-container {
  display: 'flex';
  flex-direction: 'column';
  padding: 10px;
  min-height: 572px,
}

.action-button {
  font-size: 12px;
  color: blue;
}

/* CHARTS LIST
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.charts-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin: 15px;
  grid-gap: 1rem;
  gap: 1rem;
}
.charts-container-wide {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  margin: 15px;
  grid-gap: 1rem;
  gap: 1rem;
}

.chart-container {
  min-height: 370px;
  border: 1px solid gray;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.chart-title {
  font-size: 22px;
  color: #666;
  font-weight: bold;
  padding-top: 5px;
}

.signup-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 0 !important;
}

.signup-table > thead > tr >th,  .signup-table > tbody > tr > td {
  text-align: left;
  padding: 16px;
  border: 0 !important;
}

.signup-table > tbody > tr:nth-child(even) {
  background-color: #e6e3e3;
}

.chart-type-title {
  padding-top: 20px;
  text-align: center;
}
.color-red {
  color: 'red'
}

.form-option {
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  border: 1px solid #ccc;
}
.text-align-center {
  text-align: center;
}
.flex-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.direction-column {
  flex-direction: column;
}
.admin-sell-button {
  margin: 10px;
  height: 50px;
  align-items: center;
  align-self: flex-start;
}
.no-record-container {
  display: flex;
  min-height: 400px;
  justify-content: center;
  align-items: center;
}
.display-block {
  display: block;
}
.display-none {
  display: none;
}

/*RESPONSIVE MENU V2 
––––––––––––––––––––––––––––––––––––––––––––––––––*/
.topnav {
  overflow: hidden;
  background-color: rgb(35, 42, 52);
  position: sticky;
  top: 0;
  z-index: 3;
}

.topnav a {
  float: right;
  display: flex;
  color: #f2f2f2;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 6px 20px;
  text-decoration: none;
  font-size: 17px;
}

.topnav span {
  float: right;
  display: flex;
  color: #f2f2f2;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 6px 20px;
  text-decoration: none;
  font-size: 17px;
}

/* .topnav a:hover {
  background-color: rgb(42, 47, 56);
  color: white;
} */

.topnav a.active {
  /* background-color: #4CAF50; */
  color: white;
}

.topnav .icon {
  display: none;
}

.admin-logo {
  margin: 0px auto;
  padding: 6px !important; 
  height: 60px !important; 
  float: left !important;
}

.admin-menu-icon {
  display: none !important;
  float: left !important;
}

.section-container {
  width: 100%;
  display: flex;
}

.purchase-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-height: 572px;
  overflow-x: auto;
}

.modal-content {
  background-color: rgb(254, 254, 254);
  margin: auto;
  padding: 20px;
  border: 1px solid rgb(136, 136, 136);
  width: 80%;
  border-radius: 10px;
  position: relative;
}

.modal-close {
  color: rgb(170, 170, 170);
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 18px;
  top: 4px;
}

.main-container {
  display: flex;
  flex: 1 1 0%;
  width: 100%;
  flex-direction: column;
}

.modal-purchase-container {
  display: flex;
  flex-direction: column;
  min-height: 572px;
  overflow-x: auto;
}

.error-message {
  color: red;
}

.success-message {
  color: green;
}

/* MOBILE ADMIN menu */
.overlay {
  /* height: 0; */
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 50px;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}
.user-buttons-contaiiner {
  display: flex;
  justify-content: flex-end;
}
.login-as-user-button {
  text-align: center;
  color: #ecf0f1;
  padding: 20px;
  margin: 10px 0 10px 10px;
  border-radius: 3px;
  background-color: silver;
}
.back-button-container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 5%;
  z-index: 3;
}
.back-button {
  color: rgb(170, 170, 170);
  font-size: 28px;
  font-weight: bold;
}
.profile-container-overlay{
  background-color:rgba(192,192,192,0);
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
}
.align-center {
  text-align: center;
}
.light-blue {
  color: var(--blue);
}
.margin-bottom-50 {
  margin-bottom: 50px;
}
.public-view-button{
  text-align: center;
  color: #ecf0f1;
  padding: 20px;
  margin: 10px 0;
  border-radius: 3px;
  background-color: silver;
}
.margin-right-20 {
  margin-right: 20px;
}

.date-input {
  border: 1px solid gray;
  padding: 10px;
}

.date-input-container {
  padding-top: 10px;
}


@media screen and (max-height: 450px) {
  .overlay a {font-size: 20px}
  .overlay .closebtn {
  font-size: 40px;
  top: 15px;
  right: 35px;
  }
}
/* MOBILE ADMIN menu */

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}

@media screen and (max-width: 824px) {
  .page-header, .admin-logo {display: none !important;}
  .admin-menu-icon {display: flex !important}
  .data-modal-container {padding-top: 60px;}
  .pagination {display: block;}
}


