@font-face {
    font-family: Metropolis-Semibold;
    src: url("./metropolis/Metropolis-SemiBold.otf");
}

@font-face {
    font-family: Metropolis-Thin;
    src: url("./metropolis/Metropolis-Thin.otf");
}

@font-face {
    font-family: Metropolis-Light;
    src: url("./metropolis/Metropolis-Light.otf");
}

@font-face {
    font-family: Metropolis-Regular;
    src: url("./metropolis/Metropolis-Regular.otf")
}

@font-face {
    font-family: Metropolis-Bold;
    src: url("./metropolis/Metropolis-Bold.otf");
}

@font-face {
    font-family: Metropolis-Medium;
    src: url("./metropolis/Metropolis-Medium.otf");
}