.alert-text-1 {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
    color: #FFFFFF;
    padding-left: 9px;
}
.alert-text-2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;    
    color: #FFFFFF;
    margin-top: 6px;
}
.anti-scam-wrapper {
    position: fixed;
    z-index: 4;
    width: 100%;
    padding: 14px 170px;
    background: rgba(35, 42, 52, 0.8);
}
.anti-scam-container {
    position: relative;
    display: flex;
    flex-direction: column;
}
.alert-text-1-container {
    display: flex;
    flex-direction: row;
    align-items: center;

}
.alert-text-2-container {
    margin-left: 30px;
}
.dot {
    height: 8px;
    width: 8px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    margin-right: 9px;
}
.alert-button {
    color: #FFF;
    padding: 5px;
    font-size: 16px,
}
.alert-button-container {
    margin-top: 9px;
}
.text-underline {
    text-decoration: underline;
}

@media (max-width: 1920px){
    .anti-scam-wrapper {
        padding: 14px 50px;
    }
}
@media (max-width: 1280px){
    .anti-scam-wrapper {
        padding: 14px 50px;
    }
}
@media (max-width: 960px){
    .anti-scam-wrapper {
        padding: 14px 50px;
    }
}
@media (max-width: 600px){
    .anti-scam-wrapper {
        padding: 14px 20px;
        position: fixed;
        top: 87px;
    }
}
@media (max-width: 340px){
    .anti-scam-wrapper {
        padding: 12px;
    }
}
