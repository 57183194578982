.loader {
    /* border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #f57222;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite; */
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #f57222;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  .loader-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: 'transparent'; */
        background-color: rgba(255,255,255, 0.2);
    z-index: 3;
  }
  .text-gray {
    color: gray;
    margin: 5px;
  }
  .loader-container {
    background-color: #FFFFFF;
    border-radius: 50%;
    border: 15px solid #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }